<template>
  <div class="triple-arrow-button">
    <div class="triple-arrow-button__inner" :class="{
      'triple-arrow-button__inner--small': small,
    }">
      <span>{{ text || $translate("user_guide.step_1.create_product") }}</span>
      <span>
        <triple_arrows />
      </span>
    </div>
  </div>
</template>

<script>
import triple_arrows from "./triple_arrows";

export default {
  props: {
    text: {
      type: String,
      default: "",
      required: false
    },
    small: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    triple_arrows,
  },
}
</script>

<style lang="scss">
@use "../../../../styles/_admin-constants" as *;
@use "../../../../styles/_global-constants" as *;

.triple-arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  cursor: pointer;

  &__inner {
    position: relative;
    display: flex;
    padding: $default-size 65px $default-size $double-default-size;
    font-size: $font-size--very-large;
    color: $pure-white;
    transition: 0.5s;
    box-shadow: 0 0 0 $pure-black;
    border-radius: $border-radius;
    font-weight: bold;
    background: $logo-green--darker;
    background: linear-gradient(132deg, $logo-green 40%, $logo-green--darker 77%);
    text-align: left;

    &--small {
      font-size: 22px;
      width: 100%;
    }

    span {
      transition: 0.5s;

      &:last-child {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 45px;
        width: 45px;
      }

      svg {
        height: 45px;
        width: 45px;
      }
    }

    /**************SVG****************/

    .triple-arrows__one {
      opacity: 1;
      transition: 0.2s;
      transform: translateX(-60%);
    }

    .triple-arrows__two {
      transition: 0.3s;
      transform: translateX(-30%);
    }

    path {
      opacity: 0;
    }

    &:hover .triple-arrows__one {
      transform: translateX(0);
      animation: color_change 1s infinite 0.6s;
    }

    &:hover .triple-arrows__two {
      opacity: 1;
      transform: translateX(0);
      animation: color_change 1s infinite 0.4s;
    }

    &:hover .triple-arrows__three {
      opacity: 1;
      transform: translateX(0);
      animation: color_change 1s infinite 0.2s;
    }
  }

  /* SVG animations */

  @keyframes color_change {
    0% {
      fill: $pure-white;
    }
    50% {
      fill: $logo-green;
    }
    100% {
      fill: $pure-white;
    }
  }
}
</style>
